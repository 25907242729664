import { FC } from 'react'
import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  ImageField,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { Role } from 'src/authProvider'
import { xlsxExporter } from 'src/utils/utils'

export const StaffList = ({ permissions = undefined, ...props }) => {
  return (
    <List
      exporter={xlsxExporter}
      bulkActionButtons={false}
      title="人員"
      {...props}
      sort={{ field: 'create_time', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="username" />
        <TextField source="phone" />
        <DateField source="create_time" showTime />
      </Datagrid>
    </List>
  )
}

export const StaffEdit: FC<EditProps> = (props) => (
  <Edit redirect={false} {...props}>
    <SimpleForm>
      <TextInput source="username" />

      <ArrayField label="管理店家" source="store_managements">
        <Datagrid bulkActionButtons={false}>
          <ImageField source="store.image_url" />
          <TextField label="店家" source="store.store_name" />
          <DateField label="時間" source="create_time" showTime />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
)

export const StaffCreate = (props) => {
  return (
    <Create {...props} resource="staffs/signup" redirect="/staff">
      <SimpleForm>
        <SelectInput
          source="role"
          choices={[{ id: Role.MANAGER, name: '區域管理員' }]}
        />
        <TextInput source="username" />
        <TextInput source="phone" />
        <TextInput source="password" />
        <TextInput source="confirm" />
      </SimpleForm>
    </Create>
  )
}
