import './qrcode-font.css'

import { FormControl, MenuItem } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import QRCode from 'qrcode'
import QRCodeReact from 'qrcode.react'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'

import backgroundImg from './qrcode-background.png'

export const generateStoreUrl = (
  storeId: string,
  mutationType?: string,
  storeType?: string
) => {
  const url = `https://liff.line.me/${
    process.env.REACT_APP_FRONTEND_LIFF_ID
  }?storeId=${storeId}${mutationType ? '&mutationType=' + mutationType : ''}${
    storeType ? '&storeType=' + storeType : ''
  }`
  return url
}

export const QRCodeGenerator = ({ storeId, displayOption = true }) => {
  const { control, watch } = useForm({})

  const watchAll = watch()

  const url = generateStoreUrl(
    storeId,
    watchAll.mutationType,
    watchAll.storeType
  )
  return (
    <div>
      <QRCodeReact value={url} size={200} />
      {displayOption && (
        <>
          <p>如有自行製作立牌需求，可利用以下網址</p>
          <p>{url}</p>
          <p>一般情況下不須調整以下選項</p>
          <Controller
            name="mutationType"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth sx={{ maxWidth: 200 }}>
                <InputLabel>機器類型</InputLabel>
                <Select {...field} label="機器類型">
                  <MenuItem value="借用">借用</MenuItem>
                  <MenuItem value="歸還">歸還</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="storeType"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth sx={{ maxWidth: 200 }}>
                <InputLabel>店家類型</InputLabel>
                <Select {...field} label="店家類型">
                  <MenuItem value="借用專門店">借用專門店</MenuItem>
                  <MenuItem value="簡易歸還箱">簡易歸還箱</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </>
      )}
    </div>
  )
}

const qrcodeXY = { x: 200, y: 392 }
const qrcodeSize = 720
const textColor = '#776B44'

export function Store立牌({
  storeId,
  storeName = '',
}: {
  storeId: string
  storeName?: string
}) {
  const ref = useRef<HTMLCanvasElement>(null)

  // draw background image
  useEffect(() => {
    const canvas = ref.current
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    if (!ctx) return
    const img = new Image()
    img.src = backgroundImg
    img.onload = async () => {
      canvas.width = img.width
      canvas.height = img.height
      const imgScale = canvas.width / img.width
      ctx.drawImage(img, 0, 0)
      // set height and width

      // draw QR code
      const qrurl = await QRCode.toDataURL(generateStoreUrl(storeId), {
        width: qrcodeSize,
        margin: 1,
      })
      const qr = document.createElement('img')
      qr.src = qrurl
      qr.onload = () => {
        // draw on center

        ctx.drawImage(qr, (canvas.width - qr.width) / 2 + 4, qrcodeXY.y)
      }

      // draw store name
      ctx.font = '112px qrcode'
      ctx.textAlign = 'center'
      ctx.fillStyle = textColor
      ctx.fillText(storeName, canvas.width / 2, 320)
    }
  }, [storeId, storeName])

  return (
    <div
      style={{
        width: 350,
      }}
    >
      <canvas
        ref={ref}
        style={{ width: '100%', objectFit: 'contain' }}
      ></canvas>
    </div>
  )
}

export function 立牌ToPrint({
  storeId,
  storeName = '',
}: {
  storeId: string
  storeName?: string
}) {
  return (
    <div
      id={storeName + ' ' + storeId}
      style={{
        // a4 size
        background: 'white',
        overflow: 'hidden',
        width: '210mm',
        height: '297mm',
        maxWidth: '210mm',
        maxHeight: '297mm',
        // grid
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'minmax(0, 1fr) minmax(0, 1fr)',
        gap: 0,
        padding: 0,
        margin: 0,
      }}
    >
      <Store立牌 storeId={storeId} storeName={storeName} />
      <div
        style={{
          marginLeft: 'auto',
        }}
      >
        <Store立牌 storeId={storeId} storeName={storeName} />
      </div>
      <Store立牌 storeId={storeId} storeName={storeName} />
      <div
        style={{
          marginLeft: 'auto',
        }}
      >
        <Store立牌 storeId={storeId} storeName={storeName} />
      </div>
    </div>
  )
}
