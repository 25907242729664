import Typography from '@mui/material/Typography'
import imageCompression from 'browser-image-compression'
import { useEffect } from 'react'
import {
  HttpError,
  ImageField,
  ImageInput,
  TextInput,
  useNotify,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'
import { useMutation } from 'react-query'
import highWallDataProvider from 'src/dataProvider/highWallDataProvider'
import { shortRandomId } from 'src/utils/utils'

import Loading from './Loading/Loading'

const imgurUpload = (rawFile: File) => {
  const formdata = new FormData()
  formdata.append('image', rawFile)
  return fetch('https://api.imgur.com/3/image', {
    method: 'POST',
    headers: {
      Authorization: 'Client-ID ' + process.env.REACT_APP_IMGUR_CLIENT_ID,
    },
    body: formdata,
  })
    .then((res) => {
      if (res.status < 200 || res.status >= 300) {
        return Promise.reject(new HttpError(res.status, res.statusText))
      }
      return res.json()
    })
    .then(({ json }) => {
      return json?.data?.link
    })
}

const serviceProvider = highWallDataProvider.uploadFile

const RemoteImageInput = ({ source }: { source: string }) => {
  const { setValue } = useFormContext() // retrieve all hook methods

  const pictures = useWatch({ name: 'pictures' })
  const mutation = useMutation({
    mutationFn: serviceProvider,
    onSuccess: (data) => {
      console.log(data)
      setValue('image_url', data)
    },
    onError: () => {
      setValue('pictures', undefined)
    },
  })

  const notify = useNotify()
  useEffect(() => {
    const compressAndUpload = async () => {
      const original = pictures?.rawFile
      // if not instance of File, return
      if (!original || !(original instanceof File)) return
      try {
        const compressedFile = await imageCompression(original, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        })
        // set filename
        const newFile = new File(
          [compressedFile],
          `${shortRandomId()}.${original.type.split('/')[1]}`,
          {
            type: original.type,
          }
        )

        mutation.mutate(newFile)
      } catch (error) {
        notify(`上傳照片失敗：壓縮出錯 ${JSON.stringify(error)} `, {
          type: 'warning',
        })
      }
    }
    compressAndUpload()
  }, [pictures])

  return (
    <>
      <ImageField source={source} label="圖片" />
      <ImageInput source="pictures" label="圖片">
        <ImageField
          source={mutation.isLoading || mutation.isError ? undefined : 'src'}
          title="title"
        />
      </ImageInput>
      {mutation.isLoading && <Loading />}
      {mutation.isError && (
        <Typography>{JSON.stringify(mutation.error)}</Typography>
      )}
      <TextInput source={source} label="圖片網址" />
    </>
  )
}

export default RemoteImageInput
