import { useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { addDays, format, subDays } from 'date-fns'
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceInput,
  SimpleList,
  TextField,
  usePermissions,
} from 'react-admin'
import { Role } from 'src/authProvider'
import { xlsxExporter } from 'src/utils/utils'

export const CupNumRecordList = ({ ...props }) => {
  const isSmall = useMediaQuery((theme) =>
    (theme as any).breakpoints.down('lg')
  )

  const { permissions } = usePermissions()
  return (
    <List
      exporter={xlsxExporter}
      bulkActionButtons={false}
      title="物流收送紀錄"
      {...props}
      sort={{ field: 'create_time', order: 'DESC' }}
      filterDefaultValues={{
        'create_time@_gte': format(subDays(new Date(), 14), 'yyyy-MM-dd'),
        'create_time@_lte': format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      }}
      filters={[
        <DateInput
          key="Create Time"
          label="從"
          source="create_time@_gte"
          alwaysOn
        />,
        <DateInput
          key="Create Time"
          label="到"
          source="create_time@_lte"
          alwaysOn
        />,
        <ReferenceInput
          alwaysOn
          key="staff"
          source="cup_num#store#store_managements#staff_id@_eq"
          reference="staff"
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>,
      ]}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => {
            if (permissions === Role.ADMIN || permissions === Role.MANAGER) {
              return `${record.cup_num.store.store_name} `
            } else if (permissions === Role.STORE) {
              return `編號：${record.id}`
            }
          }}
          secondaryText={(record) => {
            return (
              <div>
                <Typography variant="body1">
                  乾淨杯數：{record.clean_number_change}
                </Typography>
                <Typography variant="body1">
                  待回收杯數：{record.dirty_number_change}
                </Typography>
                <Typography variant="body1">資訊：{record.note}</Typography>
              </div>
            )
          }}
          tertiaryText={(record) =>
            `${format(new Date(record.create_time), 'yyyy-MM-dd HH:mm')}`
          }
          linkType={'show'}
        />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <DateField source="create_time" showTime />
          <TextField
            label="店家"
            source={
              permissions === Role.ADMIN
                ? 'cup_num.store.store_name'
                : permissions === Role.MANAGER
                ? 'cup_num.store.store_name'
                : 'id'
            }
          />
          <TextField label="杯款" source="cup_num.cup_type.name" />
          <TextField label="乾淨杯數" source="clean_number_change" />
          <TextField label="待回收杯數" source="dirty_number_change" />
          <TextField label="note" source="note" />
        </Datagrid>
      )}
    </List>
  )
}
